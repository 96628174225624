import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import Loader from "./components/atoms/Loader";

const Login = lazy(() => import("./pages/Login"));
const Home = lazy(() => import("./pages/Home"));
const Register = lazy(() => import("./pages/Register"));
const TicketDetails = lazy(() => import("./pages/TicketDetails"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const CreateProfile = lazy(() => import("./pages/CreateProfile"));
const Marketplace = lazy(() => import("./pages/Marketplace"));
const MarketplaceTicketDetails = lazy(
  () => import("./pages/MarketplaceTicketDetails")
);
const Settings = lazy(() => import("./pages/Settings"));

const App = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/reset-password/:token" component={ResetPassword} />
        <Route exact path="/" component={Home} />
        <Route exact path="/register/:token" component={Register} />
        <Route exact path="/r/:token" component={Register} />
        <Route exact path="/marketplace" component={Marketplace} />
        <Route exact path="/create-profile" component={CreateProfile} />
        <Route exact path="/settings" component={Settings} />
        <Route
          exact
          path="/marketplace/:id"
          component={MarketplaceTicketDetails}
        />
        <Route exact path="/:ticketId" component={TicketDetails} />
      </Switch>
    </Suspense>
  );
};

export default App;
