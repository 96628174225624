const MAIN_LIGHT_COLOR = "#fff";
const MAIN_OK_COLOR = "#E00D7B";
const LIGHT_OK_COLOR = "#bd0666";
const DANGER_COLOR = "#FF0000";
const HAPPY_COLOR = "#04651b";
const IDLE_COLOR = "#545454";
const IDLE_COLOR_BUTTON = "#E6E6E6";
const TEXT_DARK = "#050505";
const TEXT_LIGHT = "#4f4f4f";
const OK_DARK_COLOR = "#404040";
const OK_ACTIVE_COLOR = "#00AEEF";
const OK_ORANGE = "#F7921E";
const OK_GRAY = "#979797";
const OK_GRAY_LIGHT = "#757575";

const constants = {
  BUTTON_ACTIVE: MAIN_OK_COLOR,
  BUTTON_ACTIVE_HOVER: LIGHT_OK_COLOR,
  BUTTON_INACTIVE: IDLE_COLOR,
  BUTTON_DISABLED: IDLE_COLOR_BUTTON,
  BUTTON_TEXT_PRIMARY: MAIN_LIGHT_COLOR,
  INPUT_ACTIVE: OK_ACTIVE_COLOR,
  INPUT_DANGER: DANGER_COLOR,
  INPUT_SUCCESS: HAPPY_COLOR,
  INPUT_EMPTY_LABEL: OK_DARK_COLOR,
  INPUT_VALUE_COLOR: TEXT_DARK,
  OK_BRAND_COLOR: MAIN_OK_COLOR,
  MAIN_BODY_COLOR: MAIN_LIGHT_COLOR,
  LINK_COLOR: MAIN_OK_COLOR,
  TEXT_COLOR_DARK: TEXT_DARK,
  MAIN_LIGHT_COLOR,
  HAPPY_COLOR,
  DANGER_COLOR,
  TEXT_LIGHT,
  OK_ORANGE,
  OK_GRAY_LIGHT,
  OK_GRAY
};

export default constants;
