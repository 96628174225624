import React from "react";
import styled from "styled-components";
import constants from "../_globalStyles/constants";
import { heightPercentsToPx, pxToEm } from "../../util/helpers";

const Spinner = styled.div`
  border: solid ${constants.OK_BRAND_COLOR};
  border-top: solid transparent;
  border-radius: 50%;
  width: ${pxToEm(40, 16)}rem;
  height: ${pxToEm(40, 16)}rem;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const SpinnerWrap = styled.div`
  height: ${heightPercentsToPx(100)};
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  background: no-repeat
      linear-gradient(
        180deg,
        rgba(0, 174, 243, 0.2) 0,
        rgba(0, 174, 243, 0) 190px,
        rgba(0, 174, 239, 0) 100%
      ),
    #ffffff;
`;

const Loader: React.FunctionComponent = () => {
  return (
    <SpinnerWrap>
      <Spinner />
    </SpinnerWrap>
  );
};

export default Loader;
