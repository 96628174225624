import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./assets/locales/en.json";
import translationNO from "./assets/locales/no.json";
import { getCookieByName } from "../src/util/cookieProvider";

const resources = {
  en: {
    translation: translationEN
  },
  no: {
    translation: translationNO
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: getCookieByName("locale") || "no",

  interpolation: {
    escapeValue: false
  },

  react: {
    bindI18n: "languageChanged"
  }
});

export default i18n;
