import { isEmpty, isNil, without, includes, find, propEq, equals } from "ramda";
import { setCookie, getCookieByName } from "../util/cookieProvider";
import {
  format as formatDate,
  isValid,
  differenceInMilliseconds,
  formatDistanceToNowStrict
} from "date-fns";
import noDateLocale from "date-fns/locale/nb";
import enDateLocale from "date-fns/locale/en-US";

export function valueExists(value: any) {
  return !(isEmpty(value) || isNil(value));
}

export function pxToEm(px: number, fontSize: number) {
  return px / fontSize;
}

export function heightPercentsToPx(height: number) {
  const vh = window.innerHeight / 100;

  return `${pxToEm(vh * height, 16)}rem`;
}

export function capitalizeString(string: string) {
  if (typeof string !== "string") return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function removeArrayElement(element: any, array: any[]): any[] {
  return without(element, array);
}

export function isAccessDenied(permissions: any, pagePermission: any) {
  return !includes(pagePermission, permissions);
}

export function setLocale(locale: string) {
  setCookie("locale", locale, { path: "/" });
}

export function findFirstInList(condition: any, list: any[]) {
  return find(condition, list);
}

export function propEquals(prop: string, value: any) {
  return propEq(prop, value);
}

export function isInViewport(elem: Element) {
  var bounding = elem.getBoundingClientRect();
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
}

export function ticketAlreadyBooked(
  parent: boolean | undefined,
  booked: number | null | undefined,
  ticketsUsedUp: number | null | undefined,
  eligibleChildren: number | null | undefined
) {
  return typeof booked === "number" &&
    typeof ticketsUsedUp === "number" &&
    !parent
    ? booked > ticketsUsedUp
    : eligibleChildren &&
        typeof booked === "number" &&
        typeof ticketsUsedUp === "number" &&
        booked - ticketsUsedUp >= eligibleChildren;
}

export function ticketFullyBooked(available: number | null | undefined) {
  return typeof available === "number" ? available === 0 : false;
}

export function generateValidTo(validTo: Date | undefined | null) {
  return (
    validTo &&
    `${validTo.getDate() < 10 ? `0${validTo.getDate()}` : validTo.getDate()}.${
      validTo.getMonth() + 1 < 10
        ? `0${validTo.getMonth() + 1}`
        : validTo.getMonth() + 1
    }.${validTo.getFullYear()}`
  );
}
export function generateValidFrom(validFrom: Date | undefined | null) {
  return (
    validFrom &&
    `${
      validFrom.getDate() < 10 ? `0${validFrom.getDate()}` : validFrom.getDate()
    }.${
      validFrom.getMonth() + 1 < 10
        ? `0${validFrom.getMonth() + 1}`
        : validFrom.getMonth() + 1
    }.${validFrom.getFullYear()}`
  );
}

export function isValidDate(date: any) {
  return isValid(date);
}

export function getDate(date: Date) {
  let parsedDate;
  if (date instanceof Date) {
    parsedDate = date;
  } else {
    parsedDate = new Date(date);
  }

  return {
    ddmmyyyy: formatDate(parsedDate, "dd-MM-yyyy"),
    iso: formatDate(parsedDate, "yyyy-MM-dd"),
    dotSeparated: formatDate(parsedDate, "dd.MM.yyyy")
  };
}

export function millisecondsDiff(dateLeft: Date, dateRight: Date) {
  return differenceInMilliseconds(dateLeft, dateRight);
}
export function timeDistance(date: Date) {
  const locale =
    (getCookieByName("locale") === "en" && enDateLocale) || noDateLocale;

  return formatDistanceToNowStrict(date, { locale: locale });
}

export function compareObjects(obj1: any, obj2: any) {
  return equals(obj1, obj2);
}
